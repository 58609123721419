.checkbox {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
  transition: all 0.5s;
}

.checkbox:before {
  content: '✓';
  font-size: 14px;
  color: transparent !important;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #876dff;
  border-radius: 6px;
  font-weight: 700;
}


.checkbox:checked:before {
  color: #00008b !important;
  border: 1px solid #31005c;
  background: #F0EDFF;
}
